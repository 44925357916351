import React, { useContext } from "react";
import signalIcon from "@iconify/icons-mdi/circle";
import { Icon } from "@iconify/react";
import { LocationContext } from "../../App";

const SignalList = () => {
  const { signals, setSignals } = useContext(LocationContext);

  return (
    <div className="container">
      <h2 style={{ paddingTop: "40px", paddingBottom: "20px" }}>
        Traffic Signals List
      </h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {signals &&
            signals.map((signal) => (
              <tr key={signal._id}>
                <td>{signal.name}</td>
                <td>{signal.latitude}</td>
                <td>{signal.longitude}</td>
                <td>
                  <Icon
                    icon={signalIcon}
                    className={`${
                      signal.state == 4 || signal.state == 3
                        ? "signal-green"
                        : "signal-red"
                    } signal-icon fixed`}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SignalList;
