import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocationContext } from "../../App";
import { Icon } from "@iconify/react";
import signalIcon from "@iconify/icons-mdi/circle";

const CreateSignal = () => {
  const { signal, setSignal } = useContext(LocationContext);

  const handleChange = (e) => {
    setSignal({ ...signal, [e.target.name]: e.target.value });
  };
  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, lat, lng } = signal;
    fetch(ENDPOINT + "/api/signals", {
      method: "POST",
      body: JSON.stringify({
        name,
        latitude: lat,
        longitude: lng,
        state: 1,
      }),
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer abcdef",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSignal(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container boxed-border">
      <h3>Add New Traffic Signal</h3>
      <hr />
      <form onSubmit={handleSubmit} className="form-inline">
        <div className="row">
          <div className="form-group-inline col-sm-2 offset-sm-2">
            <label className="form-input">Name:</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={signal.name}
              onChange={handleChange}
              required={true}
            />
          </div>
          <div className="form-group col-sm-3">
            <label className="form-input">Latitude:</label>
            <input
              type="text"
              className="form-control"
              name="latitude"
              value={signal.lat}
              onChange={handleChange}
              required={true}
            />
          </div>
          <div className="form-group col-sm-3">
            <label className="form-input">Longitude:</label>
            <input
              type="text"
              className="form-control"
              name="longitude"
              value={signal.lng}
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col-sm-3 offset-sm-4">
            <button
              type="submit"
              className="btn btn-sm btn-primary px-5 rounded-pill mx-2"
            >
              Add
            </button>

            <a onClick={() => setSignal(null)} className="col-sm-2 subtle-btn">
              Clear
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateSignal;
