import signalIcon from "@iconify/icons-mdi/circle";
import React from "react";
import { Icon } from "@iconify/react";
import "./map.css";

const SignalPin = ({ signal, key }) => {
  return signal != null ? (
    <div className="signal" key={key}>
      <Icon
        icon={signalIcon}
        className={`${
          signal.state == 4 || signal.state == 3 ? "signal-green" : "signal-red"
        } signal-icon`}
      />
      <div className="signal-text">
        <p>{signal.name}</p>
      </div>
    </div>
  ) : null;
};

export default SignalPin;
