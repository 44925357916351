import React, { useContext } from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import { useEffect } from "react";
import { io } from "socket.io-client";
import { useState, useRef } from "react";
import { findAndReplace } from "../../utils/common";
import { LocationContext } from "../../App";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import SignalPin from "./SignalPin";
import LocationPin from "./LocationPin";
import AddSignalPin from "./AddSignalPin";
import SignalList from "./SignalList";
import CreateSignal from "./CreateSignal";
import dotenv from "dotenv";

// Load environment variables from .env file
dotenv.config();

// const actionCreators = require("../../state/index");
// const LocationPin = require("./LocationPin");

// import { Icon } from "@iconify/react";
// import locationIcon from "@iconify/icons-mdi/directions-car";
// // import "./map.css";

// const LocationPin = () => (
//   <div className="pin">
//     <Icon icon={locationIcon} className="pin-icon" />
//   </div>
// );

// const SignalPin = require("./SignalPin");

var ENDPOINT = process.env.REACT_APP_ENDPOINT;

const socket = io(ENDPOINT);
console.log("Map env", process.env.ENDPOINT);
// Google API Key:

const Map = ({ zoomLevel }) => {
  // use State
  const { location, setLocation } = useContext(LocationContext);
  const { signals, setSignals } = useContext(LocationContext);
  const { center, setCenter } = useContext(LocationContext);
  // add new signal on tap
  const { signal, setSignal } = useContext(LocationContext);

  const handleClick = (e) => {
    setSignal({
      lat: e.lat,
      lng: e.lng,
    });
  };

  // Websocket
  socket.off("location-updated");
  socket.off("signal-updated");
  socket.off("signal-created");
  socket.on("location-updated", (loc) => {
    console.log("SOCKET location-updated", loc, location);
    var newLocation = {
      lat: loc.latitude,
      lng: loc.longitude,
    };
    setLocation(newLocation);
  });

  socket.on("signal-updated", (signal) => {
    console.log("SOCKET signal-updated", signal.name, signal.state, signals);
    var signalsCopy = [...signals];
    findAndReplace(signalsCopy, signal);
    setSignals(signalsCopy);
  });

  socket.on("signal-created", (signal) => {
    console.log("SOCKET signal-created", signal.name, signal.state, signals);
    var signalsCopy = [...signals, signal];
    setSignals(signalsCopy);
  });

  const initGPS = async () => {
    // get intial position
    await fetch(ENDPOINT + "/api/gps", {
      method: "GET",
      headers: {
        Authorization: "Bearer abcdef",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.body);
        var gps = response.data.gps;
        var latLong = {
          lat: gps.latitude,
          lng: gps.longitude,
        };
        console.log("inital setup", response, latLong);
        setCenter(latLong);
        setLocation(latLong);
        // actions.updateLocation(latLong);
      });
  };

  const initSignals = async () => {
    // get intial position
    await fetch(ENDPOINT + "/api/signals", {
      method: "GET",
      headers: {
        Authorization: "Bearer abcdef",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("initSignals", response.data.signals);
        localStorage.setItem("signals", JSON.stringify(response.data.signals));
        setSignals(response.data.signals);
        // signalsRef.current = signals;
        // actions.updateSignals(response.data.signals);
      });
  };

  useEffect(() => {
    console.log("signals updated", signals);
  }, [signals]);

  useEffect(() => {
    initGPS();
    initSignals();
  }, []);

  useEffect(() => {
    if (
      center != null &&
      location !== null &&
      (Math.abs(center.lat - location.lat) > 0.005 ||
        Math.abs(center.lng - location.lng) > 0.005)
    ) {
      recenter();
    }
  }, [location]);

  const recenter = () => {
    console.log("recenter", center, location);
    setCenter({ ...location });
  };

  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          className="google-map-react"
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_API_KEY,
          }}
          center={center}
          defaultZoom={zoomLevel}
          onClick={handleClick}
        >
          {location && <LocationPin lat={location.lat} lng={location.lng} />}
          {signals &&
            signals.map((signal, index) => {
              return (
                <SignalPin
                  key={signal._id}
                  signal={signal}
                  lat={signal.latitude}
                  lng={signal.longitude}
                />
              );
            })}

          {signal && (
            <AddSignalPin signal={signal} lat={signal.lat} lng={signal.lng} />
          )}
        </GoogleMapReact>
      </div>
      <br />
      <button className="rounded-btn" onClick={recenter}>
        Re-center
      </button>
    </div>
  );
};

export default Map;
