import React, { useState, createContext } from "react";
import "./App.css";
import MapSection from "./components/map/Map";
import dotenv from "dotenv";
import CreateSignal from "./components/map/CreateSignal";
import SignalList from "./components/map/SignalList";

// Load environment variables from .env file
dotenv.config();

export const LocationContext = createContext();

function App() {
  // All values are managed at the app level
  const [location, setLocation] = useState({});
  const [signal, setSignal] = useState(null);
  const [signals, setSignals] = useState([]);
  const [center, setCenter] = useState(null);
  const contextValue = {
    location,
    setLocation,
    signal,
    setSignal,
    signals,
    setSignals,
    center,
    setCenter,
  };
  return (
    <LocationContext.Provider value={contextValue}>
      <div className="App">
        <br />
        <h3>Welcome to Traffic Management System. {process.env.ENDPOINT}</h3>
        <br />
        <center>
          <MapSection zoomLevel={parseInt(process.env.REACT_APP_ZOOM_LEVEL)} />
          <br />
          {signal && <CreateSignal signal={signal} />}
          <hr />
          {signals && <SignalList signals={signals} />}
        </center>
      </div>
    </LocationContext.Provider>
  );
}

export default App;
