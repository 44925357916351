import signalIcon from "@iconify/icons-mdi/circle";
import React from "react";
import { Icon } from "@iconify/react";
import "./map.css";

const AddSignalPin = ({ signal, key }) => {
  return signal != null ? (
    <div
      className="signal"
      key={key}
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
    >
      <Icon icon={signalIcon} className={`${"signal-yellow"} signal-icon`} />
      <div className="signal-text">
        <p>{signal.name}</p>
      </div>
    </div>
  ) : null;
};

export default AddSignalPin;
